import EgoForm from '@egodesign/form'
import EgoModal from '@egodesign/modal'
import { downloadFormGTM } from '../modules/marketing/gtm'
import { getCSRFTokenValue, getRecaptchaToken } from '../modules/form-helpers'

export const formDownload = () => {
    const downloadFormElement = document.getElementById('downloadForm')
    const modalFormElement = document.querySelector('#modal-form')
    const modalFormBtn = document.querySelectorAll('.--download-modal')

    const tmpDownloadAnchor = document.createElement('a')
    tmpDownloadAnchor.setAttribute('download', 'download')
    tmpDownloadAnchor.id = 'tmpDownloadAnchor'
    tmpDownloadAnchor.style.display = 'none'

    if (modalFormElement && modalFormBtn.length) {
        const modalForm = new EgoModal({
            element: modalFormElement,
        })

        const errMsg = modalFormElement.querySelector('#err-msg')

        const csrf = getCSRFTokenValue(downloadFormElement)

        const downloadForm = new EgoForm({
            element: downloadFormElement,
            submitType: 'fetch',
            requestHeaders: {
                'X-CSRFToken': csrf,
            },
            onSuccess: () => {
                errMsg.style.display = 'none'
                document.body.appendChild(tmpDownloadAnchor)
                tmpDownloadAnchor.click()
                document.getElementById('tmpDownloadAnchor').remove()
                setSuscribed()
                modalForm.close()
                downloadFormGTM(downloadFormElement, 'success')
            },
            onValidationError: (errorFieldsNames) => {
                if (errorFieldsNames.length) downloadFormGTM(downloadFormElement, 'validation error', errorFieldsNames)
            },
            onError: () => {
                errMsg.style.display = 'block'
                downloadFormGTM(downloadFormElement, 'error')
            },
            onBeforeSubmit: async () => {
                downloadForm.submittingForm(true);
                const recaptchaToken = await getRecaptchaToken();
                if (recaptchaToken) {
                    downloadForm.extraFields.push({
                        name: 'recaptcha_token',
                        value: recaptchaToken
                    })
                    downloadForm.resumeSubmit();
                } else {
                    downloadForm.submittingForm(false);
                    downloadForm.onError();
                }
            },
            preventSubmit: true,
        })

        modalFormBtn.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                if (!isSuscribed()) {
                    e.preventDefault()
                    const fileURL = btn.getAttribute('href') // Ruta del archivo
                    const fileName = fileURL.split('/').pop() // Nombre del archivo
                    downloadFormElement.querySelector('input[name="file_media"]').value = fileName
                    tmpDownloadAnchor.setAttribute('href', fileURL)
                    modalForm.open()
                }
            })
        })
    }

    function isSuscribed() {
        return window.localStorage.getItem('sullair-download')
    }

    function setSuscribed() {
        return window.localStorage.setItem('sullair-download', true)
    }
}
