import EgoForm from '@egodesign/form'
import EgoModal from '@egodesign/modal'
import { redirectToThankYouPage } from '../modules/tools'
import { contactFormGTM } from '../modules/marketing/gtm'
import { metaLead } from '../modules/marketing/meta-api'
import { getCSRFTokenValue, getRecaptchaToken } from '../modules/form-helpers'

export const landingsForm = () => {
    const landingsSteppedElement = document.querySelector('#landingsSteppedForm')
    const landingsElement = document.querySelector('#landingsForm')

    // Definimos la función para actualizar el contador
    function updateCounter(counterElement, seconds) {
        if (!counterElement) return
        if (seconds === 1) {
            counterElement.textContent = `${seconds} segundo` // Actualizamos el contenido del elemento
        } else {
            counterElement.textContent = `${seconds} segundos`
        }
    }

    // FORMULARIO DESK
    if (landingsElement) {
        let modalError, retrySubmitBtn, countdownInterval
        const modalErrorNode = document.querySelector('#modal-landing-form-error-desk')
        if (modalErrorNode) {
            modalError = new EgoModal({
                element: modalErrorNode,
                onClose: () => clearInterval(countdownInterval),
            })
            retrySubmitBtn = modalErrorNode?.querySelector('.btn-retry-submit')
            const btnSubmit = landingsElement.querySelector("button[type='submit']")
            retrySubmitBtn.addEventListener('click', () => {
                modalError.close()
                setTimeout(() => {
                    btnSubmit.click() // Vuelve a mandar la solicitud
                }, 1000)
            })
            modalErrorNode.querySelector('.modal-close')?.addEventListener('click', () => {
                modalError.close()
            })
        }

        // Coundown Node
        const counterElement = modalErrorNode?.querySelector('.modal-counter-sec')
        const csrfLandingsElement = getCSRFTokenValue(landingsElement)

        const landingsForm = new EgoForm({
            element: landingsElement,
            submitType: 'fetch',
            requestHeaders: {
                'X-CSRFToken': csrfLandingsElement,
            },
            resetOnSuccess: false,
            resetLoaderOnSuccess: false,
            onError: () => {
                // Deshabilitamos el botón de reintentar
                retrySubmitBtn.classList.add('--disabled')

                // Iniciamos el contador
                counterElement.parentElement.style.display = 'unset'
                let secondsLeft = 5
                updateCounter(counterElement, secondsLeft)

                // Creamos un intervalo que se ejecutará cada segundo
                countdownInterval = setInterval(() => {
                    secondsLeft-- // Restamos un segundo
                    updateCounter(counterElement, secondsLeft) // Actualizamos el contador

                    if (secondsLeft === 0) {
                        clearInterval(countdownInterval) // Detenemos el intervalo cuando llegamos a cero
                        // Ocultamos el countdown
                        counterElement.parentElement.style.display = 'none'
                        // Habilitamos en botón de reintentar
                        retrySubmitBtn.classList.remove('--disabled')
                    }
                }, 1000)

                // Abrimos el modal si aún no está abierto
                if (!modalErrorNode.classList.contains('--open')) modalError.open()

                contactFormGTM({ element: landingsElement })
            },
            onSuccess: (res) => {
                redirectToThankYouPage()
                res.json().then(({ data }) => {
                    contactFormGTM({ element: landingsElement, data })
                    metaLead(data)
                })
            },
            onValidationError: (errorFieldsNames) => {
                if (errorFieldsNames.length) contactFormGTM({ element: landingsElement, validationError: errorFieldsNames })
            },
            onBeforeSubmit: async () => {
                landingsForm.submittingForm(true);
                const recaptchaToken = await getRecaptchaToken();
                if (recaptchaToken) {
                    landingsForm.extraFields.push({
                        name: 'recaptcha_token',
                        value: recaptchaToken
                    })
                    landingsForm.resumeSubmit();
                } else {
                    landingsForm.submittingForm(false);
                    landingsForm.onError();
                }
            },
            preventSubmit: true,
        })
    }

    // FORMULARIO MOBILE
    if (landingsSteppedElement) {
        let modalErrorMob, retrySubmitBtnMob, countdownIntervalMob

        const modalErrorNodeMobile = document.querySelector('#modal-landing-form-error-mob')
        if (modalErrorNodeMobile) {
            modalErrorMob = new EgoModal({
                element: modalErrorNodeMobile,
                onClose: () => clearInterval(countdownIntervalMob),
            })
            retrySubmitBtnMob = modalErrorNodeMobile?.querySelector('.btn-retry-submit')
            const btnSubmit = landingsSteppedElement.querySelector("button[type='submit']")
            retrySubmitBtnMob.addEventListener('click', () => {
                modalErrorMob.close()
                setTimeout(() => {
                    btnSubmit.click() // Vuelve a mandar la solicitud
                }, 1000)
            })
            modalErrorNodeMobile.querySelector('.modal-close')?.addEventListener('click', () => {
                modalErrorMob.close()
            })
        }

        // Coundown Node
        const counterElementMob = modalErrorNodeMobile?.querySelector('.modal-counter-sec')
        const csrfLandingsSteppedElement = getCSRFTokenValue(landingsSteppedElement)
        const landingsSteppedForm = new EgoForm({
            element: landingsSteppedElement,
            submitType: 'fetch',
            requestHeaders: {
                'X-CSRFToken': csrfLandingsSteppedElement,
            },
            resetOnSuccess: false,
            resetLoaderOnSuccess: false,
            onStepChange: (prev, next) => {
                const prevEl = landingsSteppedElement.querySelector(`.steps__item[data-number="${prev}"]`)
                prevEl.classList.remove('--active')
                if (prev < next) prevEl.classList.add('--validated')

                const nextEl = landingsSteppedElement.querySelector(`.steps__item[data-number="${next}"]`)
                nextEl.classList.add('--active')
                if (prev < next) nextEl.classList.remove('--validated')
            },
            onError: () => {
                // Deshabilitamos el botón de reintentar
                retrySubmitBtnMob.classList.add('--disabled')

                // Iniciamos el contador
                counterElementMob.parentElement.style.display = 'unset'
                let secondsLeft = 5
                updateCounter(counterElementMob, secondsLeft)

                // Creamos un intervalo que se ejecutará cada segundo
                countdownIntervalMob = setInterval(() => {
                    secondsLeft-- // Restamos un segundo
                    updateCounter(counterElementMob, secondsLeft) // Actualizamos el contador

                    if (secondsLeft === 0) {
                        clearInterval(countdownIntervalMob) // Detenemos el intervalo cuando llegamos a cero
                        // Ocultamos el countdown
                        counterElementMob.parentElement.style.display = 'none'
                        // Habilitamos en botón de reintentar
                        retrySubmitBtnMob.classList.remove('--disabled')
                    }
                }, 1000)

                // Abrimos el modal si aún no está abierto
                if (!modalErrorNodeMobile.classList.contains('--open')) modalErrorMob.open()

                contactFormGTM({ element: landingsSteppedElement })
            },
            onSuccess: (res) => {
                redirectToThankYouPage()
                res.json().then(({ data }) => {
                    contactFormGTM({ element: landingsSteppedElement, data })
                    metaLead(data)
                })
            },
            onBeforeSubmit: async () => {
                landingsSteppedForm.submittingForm(true);
                const recaptchaToken = await getRecaptchaToken();
                if (recaptchaToken) {
                    landingsSteppedForm.extraFields.push({
                        name: 'recaptcha_token',
                        value: recaptchaToken
                    })
                    landingsSteppedForm.resumeSubmit();
                } else {
                    landingsSteppedForm.submittingForm(false);
                    landingsSteppedForm.onError();
                }
            },
            preventSubmit: true,
        })

        const nextButtons = landingsSteppedElement.querySelectorAll('.form__next-step')
        nextButtons.forEach((nextBtn) => {
            nextBtn.addEventListener('click', () => {
                setTimeout(() => {
                    const fieldsWithErrors = landingsSteppedElement.querySelectorAll('.form__field.--has-error')
                    if (!fieldsWithErrors.length) return

                    const invalidFields = []
                    fieldsWithErrors.forEach((field) => {
                        invalidFields.push(field.querySelector('.form__control')?.name)
                    })

                    contactFormGTM({ element: landingsSteppedElement, validationError: invalidFields })
                }, 200)
            })
        })
    }
}
