import store from '../../../state/store'
import EgoModal from '@egodesign/modal'

const { getCartProductById, getCartProducts, getProductById, removeAllItemsFromCart, addToCart } = store.getState()

// INICIO -- CARRITO REPUESTOS
export const createCartItem = (product) => `
    <div class="panel__container --with-data-id" data-product="${product.id}"">
        <div class="panel__content">
            <button class="btn-remove" data-product="${product.id}" data-is-quoter-btn="true" onclick="window.removeItemHandler(event)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
            </button>
            <div class="panel__image">
                <img src="${product.img}" alt="">
            </div>
            <div class="panel__text">
                <p class="title">${product.name}</p>
                <p class="--text-xs">CÓDIGO: ${product.code}</p>
            </div>
        </div>
        <div class="panel__quantity">
            <button class="btn-remove-link" data-product="${product.id}" data-is-quoter-btn="true" onclick="window.removeItemHandler(event)">
                Quitar repuesto
            </button>
            <div class="quantity">
                <button class="btn-minus" data-product="${product.id}" onclick="window.minusItemHandler(event, '${product.id}')">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.375 8.25781H12.125" stroke="#008657" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </button>
                <input value="${product.quantity}" class="product_quantity">
                <button class="btn-add" data-product="${product.id}" onclick="window.addQtyHandler(event, '${product.id}')">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.375 5.90234H5.75M10.125 5.90234H5.75M5.75 5.90234V10.2773M5.75 5.90234V1.52734" stroke="#008657" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
`

export const findProduct = (products, id) => {
    return products.find((product) => product.id === id)
}

export const getParentWithProductData = function (element) {
    if (element.dataset.product !== undefined) {
        return element
    } else if (element.parentElement !== null) {
        return getParentWithProductData(element.parentElement)
    } else {
        return null // or throw an error if this situation should never happen
    }
}

export function updateQtyInAllProducts(event, type = 'add') {
    // Select the elements with the data id that are quoter items
    const quoter_els_with_data_id = document.querySelectorAll('.quoter .--with-data-id')

    // Select the elements with the data id that are card products
    const els_with_data_id = document.querySelectorAll('.card-product.--with-data-id')

    // Merge them
    const all_items_with_data_id = [...quoter_els_with_data_id, ...els_with_data_id]

    // Filter
    all_items_with_data_id.forEach((item) => {
        if (item.dataset.product === event.target.dataset.product) {
            const prod_qty = item.querySelector('.product_quantity')

            switch (type) {
                case 'add':
                    if (prod_qty) {
                        item.classList.add('--selected')
                        prod_qty.value = getCartProductById(event.target.dataset.product).quantity
                    }
                    break

                case 'remove':
                    if (prod_qty) {
                        item.classList.remove('--selected')
                        prod_qty.innerHTML = getProductById(event.target.dataset.product).quantity
                    }
                    break

                default:
                    console.log('wrong parameter')
                    break
            }
        }
    })
}

export function updateCartItems() {
    const quoter_items_container = document.querySelector('.--cart-items-container .items')

    // Add the cart products to the DOM so you can see them in the quoter
    let cart_items = getCartProducts()
        .map((item) => createCartItem(item))
        .join('')

    quoter_items_container.innerHTML = cart_items

    updateSparePartCounter()
}

export function updateSparePartCounter() {
    const quoterQuantity = document.querySelector('#sp-cart-counter')
    if (quoterQuantity) {
        const products = getCartProducts()
        const totalQuantity = products.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.quantity
        }, 0)

        quoterQuantity.textContent = totalQuantity
    }
}

export function removeAllSparePartItems(checkoutContainer) {
    const buttonsCta = document.querySelectorAll('.--remove-all-sp')
    const confirmModal = document.querySelector('#modal-confirm-remove-sp')
    const confirmBtn = confirmModal.querySelector('.modal-confirm')
    const closeBtn = confirmModal.querySelector('.modal-close')

    // Instancia modal
    const deleteAllSpareParts = new EgoModal({
        element: confirmModal,
    })

    // Listeners
    if (buttonsCta.length) {
        buttonsCta.forEach((btn) => {
            btn.addEventListener('click', () => {
                // OPEN MODAL TO CONFIRM
                deleteAllSpareParts.open()
            })
        })
    }

    if (confirmBtn) {
        confirmBtn.addEventListener('click', () => {
            // UPDATE STATE
            removeAllItemsFromCart()

            // UI CHANGE
            updateCartItems()
            updateSparePartCounter()

            if (checkoutContainer) {
                let cart_products = getCartProducts()

                if (!cart_products.length > 0) {
                    checkoutContainer.classList.add('--no-detail')
                }
            } else {
                const quoterItemsContainer = document.querySelector('.quoter .items')

                if (quoterItemsContainer.childNodes.length === 0) {
                    const quoter = document.querySelector('.quoter')
                    quoter.classList.add('--hidden')
                }
            }

            // CLOSE MODAL
            deleteAllSpareParts.close()
        })
    }

    if (closeBtn) {
        closeBtn.addEventListener('click', () => deleteAllSpareParts.close())
    }
}

export function addSparePartFromSelector(quoter) {
    const addButtons = document.querySelectorAll('.spare-parts-selector__add-btn')
    if (addButtons.length) {
        addButtons.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault()
                const field = e.target.previousElementSibling
                const selector = field.querySelector('select')
                const sparePartVal = selector.value

                // STATE CHANGE
                addToCart(sparePartVal)

                // UI CHANGE
                if (quoter && quoter.classList.contains('--hidden')) {
                    quoter.classList.remove('--hidden')
                }

                updateCartItems()
            })
        })
    }
}
// FIN -- CARRITO REPUESTOS

export const docReady = (fn) => {
    if (document.readyState != 'loading' && typeof fn === 'function') {
        fn()
    } else {
        document.addEventListener('DOMContentLoaded', fn)
    }
}

export const vanillaFade = ({ element, enter, time, displayType, callback }) => {
    element.style.opacity = enter ? 0 : 1
    if (enter) element.style.display = displayType || 'block'

    let last = +new Date()
        ; (function fade() {
            if (enter) element.style.opacity = +element.style.opacity + (new Date() - last) / (time || 200)
            else element.style.opacity = +element.style.opacity - (new Date() - last) / (time || 200)
            last = +new Date()

            if ((enter && +element.style.opacity < 1) || (!enter && +element.style.opacity > 0)) {
                ; (window.requestAnimationFrame && requestAnimationFrame(fade)) || setTimeout(fade, 16)
            } else {
                if (!enter) element.style.display = 'none'
                if (callback && typeof callback === 'function') callback()
            }
        })()
}

const findAnimationElement = () => {
    const bodyCurrent = document.body.scrollTop,
        docElCurrent = document.documentElement.scrollTop
    document.documentElement.scrollTop = document.body.scrollTop = 10
    let animate
    if (document.body.scrollTop > 0) {
        animate = document.body
        document.body.scrollTop = bodyCurrent
    } else {
        animate = document.documentElement
        document.documentElement.scrollTop = docElCurrent
    }
    return animate
}

export const animatedScrollTo = ({ target, duration, offset }) => {
    const finishAt = Date.now() + duration,
        animate = findAnimationElement()
    requestAnimationFrame(tick)

    function tick() {
        const framesLeft = (finishAt - Date.now()) / 16.6
        let distance = target ? target.getBoundingClientRect().top : 0
        if (offset) distance = distance + offset
        if (distance == 0) return
        const direction = Math.sign(distance)

        if (framesLeft < 2 && framesLeft > -2) {
            // Last call
            animate.scrollTop += distance
        } else {
            if (direction == -1) animate.scrollTop -= Math.max(1, distance / framesLeft)
            else animate.scrollTop += Math.max(1, distance / framesLeft)
            requestAnimationFrame(tick)
        }
    }
}

export const getCookie = function (name) {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
                break
            }
        }
    }
    return cookieValue
}

export const getCsrfToken = () => {
    const wagtailConfig = JSON.parse(document.getElementById("wagtail-config").textContent);
    return wagtailConfig["CSRF_TOKEN"];
}

export const isElementVisible = (element) => {
    return window.getComputedStyle(element).display !== 'none'
}

export const debounce = (callback, wait) => {
    let timerId
    return (...args) => {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            callback(...args)
        }, wait)
    }
}

export const getNavigationEVent = () => {
    return window.performance.getEntriesByType('navigation')[0].type
}

export const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000)
    let seconds = ((millis % 60000) / 1000).toFixed(0)

    if (seconds == 60) {
        ++minutes
        seconds = 0
    }

    return { minutes: minutes, seconds: seconds }
}

export const filterNumber = (value) => {
    let reg = /[^0-9]/g
    return value.replace(reg, '')
}

export const filterNonDigits = (value) => {
    let reg = /[^\w,\d]/g
    return value.replace(reg, '')
}

export const filterMoneyAmount = (num, currency = '$', round = false) => {
    if (!num || num == currency) return ''
    if (round) num = Math.round(num)
    let result = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    if (!result) return ''
    return `${currency}${result}`
}

export function redirectToThankYouPage() {
    window.location.href = `/gracias`;
}

// Tooltip
export function handleTooltip(nodes) {
    const isFinePointer = window.matchMedia('(pointer:fine)').matches
    if (!isFinePointer) return
    Array.from(nodes).forEach((el) => {
        let tip = document.createElement('div')
        tip.classList.add('tooltip')
        tip.innerText = el.getAttribute('tooltip')
        let delay = el.getAttribute('tooltip-delay')
        if (delay) {
            tip.style.transitionDelay = delay + 'ms'
        }
        tip.style.transform = 'translate(' + (el.hasAttribute('tooltip-left') ? 'calc(-100% - 5px)' : '15px') + ', ' + (el.hasAttribute('tooltip-top') ? '-100%' : '0') + ')'
        el.appendChild(tip)
        el.onmousemove = (e) => {
            tip.style.left = e.clientX + 'px'
            tip.style.top = e.clientY + 'px'
        }
    })
}

export function normalizeString(str) {
    return str
        .toLowerCase()
        .normalize('NFD') // Descompone los caracteres diacríticos
        .replace(/[\u0300-\u036f]/g, '') // Elimina los caracteres diacríticos
        .replace(/\s+/g, ' ') // Reemplaza múltiples espacios por uno solo
        .trim(); // Elimina espacios al principio y al final
}
