import EgoModal from '@egodesign/modal'
import { getCookie } from './tools'

function updateCounter(seconds, element) {
    if (seconds === 1) {
        element.textContent = `${seconds} segundo`
    } else {
        element.textContent = `${seconds} segundos`
    }
}

const countdown = {
    interval: null,
}

export function handleRetrySubmitButton(modalErrorNode) {
    const counterElement = modalErrorNode?.querySelector('.modal-counter-sec')
    const retrySubmitBtn = modalErrorNode?.querySelector('.btn-retry-submit')

    retrySubmitBtn.classList.add('--disabled')

    // Iniciamos el contador
    counterElement.parentElement.style.display = 'unset'
    let secondsLeft = 5
    updateCounter(secondsLeft, counterElement)

    // Creamos un intervalo que se ejecutará cada segundo
    countdown.interval = setInterval(() => {
        secondsLeft-- // Restamos un segundo
        updateCounter(secondsLeft, counterElement) // Actualizamos el contador

        if (secondsLeft === 0) {
            clearInterval(countdown.interval) // Detenemos el intervalo cuando llegamos a cero
            // Ocultamos el countdown
            counterElement.parentElement.style.display = 'none'
            // Habilitamos en botón de reintentar
            retrySubmitBtn.classList.remove('--disabled')
        }
    }, 1000)
}

export function handleModalErrorRetry(modalErrorNode, btnSubmit) {
    if (modalErrorNode) {
        const modalErrorInstance = new EgoModal({
            element: modalErrorNode,
            onClose: () => clearInterval(countdown.interval),
        })
        const retrySubmitBtn = modalErrorNode?.querySelector('.btn-retry-submit')
        retrySubmitBtn.addEventListener('click', () => {
            modalErrorInstance.close()
            setTimeout(() => {
                btnSubmit.click() // Vuelve a mandar la solicitud
            }, 1000)
        })
        modalErrorNode.querySelector('.modal-close')?.addEventListener('click', () => {
            modalErrorInstance.close()
        })

        return modalErrorInstance
    }
}

export function getCSRFTokenValue(form) {
    return form.querySelector('[name=csrfmiddlewaretoken]').value
}

export function setUserId() {
    const userIdInputs = document.querySelectorAll('[name="user_id"]')
    if (userIdInputs.length) {
        const gaCookie = getCookie('_ga')
        userIdInputs.forEach((input) => {
            if (gaCookie && !input.value) input.value = gaCookie
        })
    }
}

export async function getRecaptchaToken() {
    const publicKey = process.env.RECAPTCHA_PUBLIC_KEY
    if (!publicKey) return null;

    return await window.grecaptcha.execute(publicKey, { action: 'submit' });
}

/**
 * Validate a reCAPTCHA token and resume the form submission if successful.
 *
 * @param {EgoForm} instance - The EgoForm instance to resume the submission.
 * @param {string} csrf - The CSRF token to include in the validation request.
 *
 * @throws {Error} If either instance or csrf are missing.
 * @throws {Error} If the reCAPTCHA public key is missing.
 * @throws {Error} If the reCAPTCHA token is not received.
 */
export const validateRecaptcha = async (instance, csrf) => {
    if (!instance || !csrf) {
        throw new Error('Missing required parameters')
    }

    const publicKey = process.env.RECAPTCHA_PUBLIC_KEY
    if (!publicKey) {
        throw new Error('Missing recaptcha public key')
    }

    try {
        instance && instance.submittingForm(true);
        const token = await window.grecaptcha.execute(publicKey, { action: 'submit' })
        if (!token) throw new Error('No captcha token received')

        const response = await fetch('/formulario/validacion-recaptcha/', {
            method: 'POST',
            body: new URLSearchParams({
                token: token,
                csrfmiddlewaretoken: csrf,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        const data = await response?.json()
        if (data?.success) {
            instance.resumeSubmit()
        } else {
            instance.onError && instance.onError()
            instance && instance.submittingForm(false);

        }
    } catch (error) {
        instance.onError && instance.onError()
        instance && instance.submittingForm(false);
    }
}
