import EgoForm from '@egodesign/form';
import EgoModal from '@egodesign/modal'
import { redirectToThankYouPage } from '../modules/tools'
import { getCSRFTokenValue, getRecaptchaToken } from '../modules/form-helpers';

export const surveyForm = () => {
    const surveyForm = document.querySelector('#surveyForm')


    if (surveyForm) {
        // Selectors
        const submitButton = surveyForm.querySelector('button[type=submit]')
        const dynamicTriggers = surveyForm.querySelectorAll('[dynamic-trigger]')
        const cards = surveyForm.querySelectorAll('.form-survey__card')
        const progressBar = document.querySelector('.header__progress-bar__fill')
        const questionsNumber = document.querySelector('#quesitonsNumber')
        const progressNumber = document.querySelector('#completedCards')

        // Modal Error
        let modalError;
        const modalErrorNode = document.querySelector('#modal-form-contact-error')
        const retrySubmitBtn = modalErrorNode.querySelector(".btn-retry-submit")

        if (modalErrorNode) {
            modalError = new EgoModal({
                element: modalErrorNode,
            })
            retrySubmitBtn.addEventListener('click', () => {
                modalError.close()
                setTimeout(() => {
                    submitButton.click() // Vuelve a mandar la solicitud
                    submitButton.classList.add('--loading')
                }, 1000)
            })
            modalErrorNode.querySelector('.modal-close')?.addEventListener('click', () => {
                modalError.close()
            })
        }

        // Functions
        const updateProgressBar = () => {
            const completedCards = surveyForm.querySelectorAll('.form-survey__card.--completed').length
            const percentage = (completedCards / questionsNumber.textContent) * 100
            progressBar.style.width = `${percentage}%`
            progressNumber.textContent = completedCards
        }
        // Form logic
        const csrf = getCSRFTokenValue(surveyForm)
        const form = new EgoForm({
            element: surveyForm,
            submitType: 'fetch',
            debug: false,
            submitDataFormat: 'formData',
            requestHeaders: {
                'X-CSRFToken': csrf,
            },
            serializerIgnoreList: ['ignore'],
            classes: {
                requiredField: '--required',
                requiredIfFilledField: '--required-if-filled',
                fieldHasError: '--has-error',
                controlHasError: 'is-danger',
                hiddenErrorMessage: 'is-hidden',
                formSubmittingState: '--submitting',
                buttonSubmittingState: '--loading'
            },
            onValidationError: fields => {
                // Remove completed class from cards with errors
                fields.forEach(field => {
                    const input = surveyForm.querySelector(`[name='${field}']`)
                    const card = input.closest('.form-survey__card')
                    if (card.classList.contains('--completed')) {
                        card.classList.remove('--completed')
                    }
                });
                // Update progress bar
                updateProgressBar()
            },
            onSubmitStart: () => {
                submitButton.classList.add('--loading')
            },
            onSubmitEnd: () => {
                submitButton.classList.remove('--loading')
            },
            onSuccess: () => redirectToThankYouPage('encuesta'),
            onError: err => {
                submitButton.classList.remove('--loading')
                retrySubmitBtn.classList.remove('--disabled')

                // Abrimos el modal si aún no está abierto
                if (!modalErrorNode.classList.contains('--open')) modalError.open()
                console.log('Error', err)
            },
            onBeforeSubmit: async () => {
                form.submittingForm(true);
                const recaptchaToken = await getRecaptchaToken();
                if (recaptchaToken) {
                    form.extraFields.push({
                        name: 'recaptcha_token',
                        value: recaptchaToken
                    })
                    form.resumeSubmit();
                } else {
                    form.submittingForm(false);
                    form.onError();
                }
            },
            preventSubmit: true,
        });

        // Dinamc fields logic
        dynamicTriggers.forEach(trigger => {
            trigger.addEventListener('change', () => {
                const field = trigger.getAttribute('dynamic-trigger')
                if (trigger.value == 'Sí' || trigger.value == 'Otro') {
                    const subquestion = surveyForm.querySelector(`[dynamic-field="${field}"]`)
                    subquestion.classList.remove('--hidden')
                    subquestion.classList.add('--required')
                } else {
                    const subquestion = surveyForm.querySelector(`[dynamic-field="${field}"]`)
                    subquestion.classList.add('--hidden')
                    subquestion.classList.remove('--required')

                    const card = subquestion.closest('.form-survey__card')
                    if (card.querySelector('.--has-error')) {
                        card.querySelector('.--has-error').classList.remove('--has-error')
                    }
                }
            })
        })
        // Completed cards logic
        cards.forEach(card => {
            // Check if the card is completed
            card.addEventListener('change', () => {
                // Logic for radio
                if (card.querySelector('.form__field').getAttribute('data-type') == 'radio') {
                    const inputs = card.querySelectorAll('input')
                    for (let i = 0; i < inputs.length; i++) {
                        if (inputs[i].checked == true) {
                            card.classList.add('--completed')
                        }
                    }
                }
                // Logic for checkbox
                else if (card.querySelector('.form__field').getAttribute('data-type') == 'checkbox') {
                    const inputs = card.querySelectorAll('input')
                    let checked = 0
                    for (let i = 0; i < inputs.length; i++) {
                        if (inputs[i].checked == true) {
                            checked++
                        }
                    }
                    if (checked > 0) {
                        card.classList.add('--completed')
                    } else {
                        card.classList.remove('--completed')
                    }
                }
                // Logic for text
                else {
                    const field = card.querySelector('.form__field')
                    if (field.classList.contains('--filled')) {
                        card.classList.add('--completed')
                    }
                }
                // Update progress bar
                updateProgressBar()
            })
        })
    }
}
